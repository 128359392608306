import React from 'react';
import { Container, Typography} from '@material-ui/core';
import Layout from '../components/layout';
import Link from 'gatsby-link';
import SEO from '../components/seo'

const cookiePolicy = () => (
    <Layout location="">
        <SEO description='Blix Privacy Policy cookie' title='Cookie Policy'/>
        <Container fixed style={{paddingTop: '150px', paddingBottom: '50px'}}>
            <Typography color='textPrimary'>Cookie Policy</Typography>
            <Typography color='textSecondary'>
            <div id='privacy2'>


                <p>
                Capitalized terms used in this policy but not defined have the meaning set forth in our <Link style={{color: '#1AA3FF'}} to='/privacy/'>Privacy Policy</Link>, which also includes additional details about the collection and use of information at Blix Inc.
                </p>
                <p>
                A cookie is a small text file that is stored on a user’s computer for record-keeping purposes. Cookies may be used by third parties such as Google Analytics on our Websites, but we do not link the information in those cookies to any personal information you submit. They are used to make it easier for you to navigate the Blix Websites and so we can analyze the interests of our users to enhance the experience on our site. We do not have access or control over these cookies, and they are not covered by our Privacy Policy.
                </p>
        </div>
        </Typography>
    </Container>
    </Layout>
);

export default cookiePolicy;